import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { red } from "@mui/material/colors";

import Home from "./Pages/Home";
import ResponsiveAppBar from "./Components/ResponsiveAppBar";
import Footer from "./Components/Footer";

const theme = createTheme({
  primary: red,
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* <Container maxWidth='xl'> */}
      <ResponsiveAppBar />
      <Home theme={theme} />
      <Footer />
      {/* </Container> */}
    </ThemeProvider>
  );
}
export default App;
