import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";

function ButtonIpCopySnackbar() {
  const [open, setOpen] = React.useState(false);

  const handleClick = (text) => {
    navigator.clipboard.writeText(text);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        action={action}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Copied IP to clipboard
        </Alert>
      </Snackbar>

      <Tooltip title="Click to Copy Java IP" placement="top">
        <Button
          variant="outlined"
          // color="success"
          onClick={() => handleClick("mcverse.city")}
          sx={{
            width: "100%",
            marginBottom: "10px",
            color: "white",
            fontSize: "20px",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          JAVA IP: MCVerse.City
        </Button>
      </Tooltip>

      <div sx={{ width: "100%" }}>
        <Tooltip title="Click to Copy Bedrock IP">
          <Button
            variant="outlined"
            // color="success"
            onClick={() => handleClick("mcverse.city")}
            sx={{ color: "white" }}
          >
            BEDROCK IP
          </Button>
        </Tooltip>

        <Tooltip title="Click to Copy Bedrock Port">
          <Button
            variant="outlined"
            // color="success"
            onClick={() => handleClick("19132")}
            sx={{ marginLeft: "10px", color: "white" }}
          >
            BEDROCK PORT 19132
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
export default ButtonIpCopySnackbar;
