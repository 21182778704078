import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Chip from "@mui/material/Chip";
import ButtonIpCopySnackbar from "../Components/ButtonIpCopySnackbar";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const survivalChips = [
  "Hard Survival",
  "1.21",
  "Permanent Claims",
  "Player Warps",
  "Searchable Player Market",
  "Fishing Competitions",
  "Death Chest",
  "PvP Toggle",
  "Dragon Slaying",
  "Phantom Toggle",
  "Simple Clans",
  "Big Doors",
  "Mini Blocks",
  "Image Frames",
  "Full Mob Spawns",
  "Proximity Chat",
  "Minepacks",
  "Spawners",
  "Player and Server Stats",
];
const extractionChips = [];

const itemData = [
  {
    img: "imgs/player-builds/2023-07-20_23.06.32.png",
    title: "PsukheKratos",
    rows: 2,
    cols: 4,
  },
  {
    img: "imgs/player-builds/2023-08-05_11.03.15.png",
    title: "EcS Base",
    rows: 2,
    cols: 2,
  },
  {
    img: "imgs/player-builds/2024-01-22_22.11.43.png",
    rows: 1,
    cols: 2,
  },
  {
    img: "imgs/player-builds/2023-08-11_14.26.41.png",
    title: "BugsBunny93",
  },
  {
    img: "imgs/player-builds/2023-09-14_08.32.10.png",
  },
  {
    img: "imgs/player-builds/2023-11-30_08.38.29.png",
    rows: 2,
    cols: 2,
  },
  {
    img: "imgs/player-builds/2024-01-10_15.51.39.png",
    rows: 2,
    cols: 2,
  },
  {
    img: "imgs/player-builds/2024-01-17_19.58.54.png",
    rows: 2,
    cols: 2,
  },
  {
    img: "imgs/player-builds/2024-02-19_22.34.37.png",
  },
  {
    img: "imgs/player-builds/2024-02-19_22.39.54.png",
  },
  {
    img: "imgs/player-builds/2024-02-19_22.40.26.png",
  },
  {
    img: "imgs/player-builds/2024-02-29_22.51.45.png",
  },
  {
    img: "imgs/player-builds/DrWho.png",
    cols: 2,
  },
  {
    img: "imgs/player-builds/House.png",
    rows: 1,
    cols: 2,
  },
  {
    img: "imgs/player-builds/hsh.png",
    rows: 2,
    cols: 2,
  },
  {
    img: "imgs/player-builds/image0.jpg",
    rows: 2,
    cols: 1,
  },
  {
    img: "imgs/player-builds/MinecraftChillin.png",
  },
  {
    img: "imgs/player-builds/Night_.png",
  },
  {
    img: "imgs/player-builds/preview.png",
    rows: 2,
    cols: 2,
  },
  {
    img: "imgs/player-builds/fishing_getaway.png",
    rows: 2,
    cols: 2,
  },
];

export default function Home(theme) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            // bgcolor: "background.paper",
            backgroundImage: "url(imgs/player-builds/2024-02-29_22.50.48.png)",
            backgroundSize: "cover",
            pt: 14,
            pb: 6,
            paddingBottom: "80px",
          }}
        >
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="white"
              gutterBottom
            >
              Minecraft Builder Survival
            </Typography>
            <Typography variant="h5" align="center" color="white" paragraph>
              {/* Builder-Friendly
              <br />
              Player obtained blocks and markets.
              <br />
              Buy and Sell Player builds (Experimental) */}
              A laid back minecraft community for adults.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <ButtonIpCopySnackbar />
            </Stack>
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="lg">
          {/* End hero unit */}

          <Typography
            component="h3"
            variant="h3"
            color="text.primary"
            gutterBottom
          >
            Server Features
          </Typography>

          <Grid container spacing={4}>
            {/* 26k Days Survival */}
            <Grid item key="surival-info" xs={12} sm={8} md={8}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* <CardMedia
                  component="div"
                  sx={{
                    // 16:9
                    pt: '56.25%',
                  }}
                  image={process.env.PUBLIC_URL + '/logo512.png'}
                /> */}
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    26k Days Survival
                  </Typography>
                  <Typography>
                    <List>
                      <ListItem>
                        Long term classic survival in a large open world. Play
                        at your own pace and terms.
                      </ListItem>
                      <ListItem>
                        All player mined blocks. Search the player markets to
                        boost your resources and save time.
                      </ListItem>
                      <ListItem>
                        Buy and Sell Builds. (Experimental WIP)
                      </ListItem>
                      <ListItem>
                        Free Rank upgrades as you play. Purchase Citizen with
                        $15,000 game cash.
                      </ListItem>
                    </List>
                  </Typography>

                  <Container sx={{ marginTop: "20px", paddingLeft: "5px" }}>
                    {survivalChips.map((chip) => (
                      <Chip
                        label={chip}
                        variant="outlined"
                        sx={{ margin: "3px" }}
                      />
                    ))}
                  </Container>
                </CardContent>
              </Card>
            </Grid>

            <Grid item key="surival-features" xs={12} sm={4} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    Features
                  </Typography>
                  <List>
                    <ListItem>Large limitless world</ListItem>
                    <ListItem>Free 50k+ Claim Blocks</ListItem>
                    <ListItem>20 TPS most of the time</ListItem>
                    <ListItem>Hope to be around long time</ListItem>
                    <ListItem>Existing AceCraft &lt;3 Community</ListItem>
                    <ListItem>Builder Friendly, No Grief!</ListItem>
                    <ListItem>Fisher Friendly</ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Typography
            component="h3"
            variant="h3"
            color="text.primary"
            gutterBottom
            marginTop="40px"
          >
            Player Builds
          </Typography>
          <Typography>
            Submit your own in discord build-share channel.
            <br />
            Built under vanilla survival rules.
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12}>
              <Card>
                <ImageList
                  sx={{ width: "100%", height: "100%" }}
                  variant="quilted"
                  cols={4}
                  rowHeight={240}
                >
                  {itemData.map((item) => (
                    <ImageListItem
                      key={item.img}
                      cols={item.cols || 1}
                      rows={item.rows || 1}
                    >
                      <img
                        {...srcset(item.img, 121, item.rows, item.cols)}
                        alt={item.title}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </main>
    </ThemeProvider>
  );
}
